.related-users-svg-line {
    stroke: #649dbb;
    stroke-width: 2;
    stroke-dasharray: 80;
}

.related-users-container {
    display: flex;
    flex-direction: column;
}

.related-users-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
}

.related-users-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    background-color: var(--soft-orange);
}

.related-users-features {
    width: 40%;
}

.related-users-logo-container {
    display: flex;
    flex-direction: column;
    width: 420px;
    margin-right: 30px;
}
