.main-container {
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}
.banner-icons-column {
  height: 200px;
}

.banner-icons-container {
  position: relative;
  height: 200px;
  overflow: visible;
}

.banner-center-content {
  display: flex;
  justify-content: center;
}

.banner-flex-column {
  display: flex;
  flex-direction: column;
}

.banner-skills-text-row {
  z-index: 2;
  font-size: 20px;
  text-align: center;
}

.banner-overlay {
  position: absolute;
  width: 100%;
}

.banner-aws-certified {
  /* position: absolute;
  top: 40px;
  right: 60px; */
  /* position: relative; */
  z-index: 10;
  margin-top: -10px;
}

.banner-avatar-container {
  /* position: absolute;
  top: 60px;
  left: 60px; */
  margin-left: 25px;
  margin-top: 25px;
}

.banner-avatar {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  background-color: var(--white);
  position: relative;
  z-index: 10;
}
.developer-text {
  text-align: center;
}
.banner-sub-title {
  position: absolute;
  top: 400px;
  width: 100%;
  text-align: center;
}

.banner-sub-sub-title {
  margin-bottom: 10px;
  position: relative;
  z-index: 10;
}
.banner-sub-sub-title-portfolio {
  position: relative;
  z-index: 10;
  font-size: 1.5rem;
}
.banner-sub-sub-title-developer {
  font-size: 4rem;
  margin-bottom: 10px;
  font-weight: 300;
}
a.banner-sub-sub-title-link {
  color: #555;
  margin-left: 35px;
}

a:visited.banner-sub-sub-title-link {
  color: #555;
}

.banner-sub-sub-title-icon-container {
  position: relative;
}

.banner-sub-sub-title-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  left: -30px;
  top: -4px;
}

.canvas {
  position: relative;
  top: 150px;
}

@media (max-width: 860px) {
  .main-container {
    flex-direction: column;
    justify-content: center;
    align-items:center;
  }
  .banner-aws-certified {
    display: none;
  }
  .banner-sub-sub-title-portfolio{
    display: none;
    /* font-size: smaller; */
  }
  .banner-sub-sub-title-developer{
      font-size: 1.2rem;
      position: relative;
      top: 30px;
  }
  
  .banner-skills-text-row {
    font-size: smaller;
  }
}
