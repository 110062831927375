.swishboom-pages-container {
    position: relative;
}

.swishboom-page-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
}

.swishboom-page-logo-container {
    display: flex;
    flex-direction: column;
    width: 420px;
    margin-left: 40px;
}

.swishboom-page {
    position: absolute;
    top: 0;
    width: 100%;
    height: 700px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /*border-right: 1px solid #555;*/
    background-color: var(--soft-orange);
}