.chat-window {
    width: 100%; /* Set the width to full viewport width */
    height: 100vh; /* Full viewport height */
    display: flex;
    flex-direction: column;
    background: #f0f0f0;
    overflow: hidden;
    align-items: center;
}

.chat-container {
    flex: 1;
    width: 50%;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    border-radius: 10px;
    background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    overflow: hidden; /* Ensure child elements handle scrolling */
}

.chat-robot-container {
    display: flex;
    justify-content: center;
    padding: 20px 0;
}

/* Container for messages */
.chat-messages {
    flex: 1;
    overflow-y: auto;
    padding: 20px;
}

/* Styling for each message */
.chat-message {
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
    animation: slideIn 0.5s ease-out;
}

/* Add icons for each sender */
.chat-message .icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    object-fit: cover;
}

/* Style for messages from the user */
.chat-message.user {
    justify-content: flex-end;
}

.chat-message.user .icon {
    order: 2;
    margin-left: 10px;
    margin-right: 0;
}

.chat-message.user .text {
    order: 1;
    background-color: var(--extra-soft-red);
    color: #555;
    padding: 8px 12px;
    border-radius: 15px;
    max-width: 60%;
    word-wrap: break-word;
}

/* Style for messages from robot */
.chat-message.robot .text {
    background-color: #ffffff;
    color: #555;
    padding: 8px 12px;
    border-radius: 15px;
    max-width: 60%;
    word-wrap: break-word;
}

/* Input area at the bottom */
.chat-input-area {
    display: flex;
    padding: 10px;
    background: #fff;
    border-top: 1px solid #ccc;
    flex-shrink: 0; /* Ensure it doesn't shrink */
}

.chat-input-area input {
    flex-grow: 1;
    padding: 10px;
    border: none;
    border-radius: 20px;
    margin-right: 10px;
}

.chat-input-area button {
    padding: 10px 20px;
    background-color: var(--soft-red);
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}

/* Animation for message entries */
@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
