.features-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.features-list {
    list-style-type: none;
}

.features-list-item {
    margin: 50px 0 50px 0;
    transition: opacity 0.3s ease;
}

.features-list-item.hidden {
    opacity: 0;
}

.features-list-item.visible {
    opacity: 1;
}
