.footer-container {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--bright-blue);
  height: var(--footer-height);
}

.footer-favicon-link {
  flex: 0 0 auto;
}

.footer-favicon {
  width: 36px;
  height: 36px;
  margin: 10px;
}

.footer-link {
  color: var(--white);
  font-size: 1.5em;
  margin: 15px;
  cursor: pointer;
}

a.footer-link {
  text-decoration: none;
}

.footer-copyright {
  color: var(--white);
  font-size: 1.2em;
  margin: 20px;
  flex: 0 0 auto;
}

@media (max-width: 900px) {
  .footer-link {
    display: none;
  }
}
