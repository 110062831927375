.hupres-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.hupres-phone-message-feed-container {
  position: absolute;
  width: 100%;
  margin-top: 80px;
  height: 680px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: end;
  z-index: -11;
  margin-bottom: 40px;
}

.hupres-phone-message-feed {
  flex: 0 0 auto;
  width: 240px;
  transform-origin: bottom left;
  transform: skewY(-2deg) skewX(8deg) translateX(80px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.hupres-logo-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 30vw;
  height: 100vh;
  margin-left: 20px;
}

.hupres-logo-svg {
  width: 200px;
  height: auto;
}

.hupres-phone-overlay {
  width: 100%;
  position: relative;
}

.hupres-phone-before {
  width: 100%;
  top: -905px;
  height: 1000px;
  position: absolute;
  background-color: var(--background);
  z-index: -10;
}

.hupres-phone-after {
  width: 100%;
  top: -25px;
  height: 1000px;
  position: absolute;
  background-color: var(--background);
  z-index: -10;
}

.hupres-phone-screen-container {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 50%; /* Center horizontally */
  top: 50%; /* Center vertically */
  transform: translate(
    -50%,
    -50%
  ); /* Adjust based on the actual size to center perfectly */
  overflow: hidden;
  z-index: -10;
}

.hupres-phone-screen-svg {
  margin-top: 80px;
  margin-left: 30px;
  width: auto;
  height: 700px;
  position: relative;
  left: 50%; /* Center horizontally */
  transform: translate(
    -50%,
    0%
  ); /* Adjust based on the actual size to center perfectly */
  z-index: -9;
}

.hupres-human-svg {
  width: 400px;
  height: 100%;
  position: absolute;
  left: 100%; /* Center horizontally */
  top: 60px;
  transform: translate(
    -110%,
    -20%
  ); /* Adjust based on the actual size to center perfectly */
  z-index: -20;
}

.hupres-chat-message-container.is-bot {
  display: flex;
  flex-direction: row;
}

.hupres-chat-message-content {
  padding: 15px;
  background-color: #fff;
  border: 1px solid #eee;
  margin: 10px 0;
  display: inline-block;
}

.hupres-chat-message {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.hupres-chat-options {
  margin: 6px;
}

.hupres-chat-message-content.is-bot {
  border-radius: 16px 16px 16px 0;
  text-align: left;
  align-self: flex-start;
}

.hupres-chat-message-content.is-human {
  border-radius: 16px 16px 0 16px;
  text-align: right;
  align-self: flex-end;
}

@media (max-width: 900px) {
  .hupres-logo-container {
    flex-direction: row;
    margin-bottom: 20px;
    position: relative;
    top: 800px;
  }
  .features-list-container{
    align-items:start;
    justify-content: flex-start;
  }
  .hupres-human-svg{
    display: none;
  }
}
