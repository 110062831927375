body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --black: #000000;
  --ash-black: #222;
  --white: #fafafa;
  --sky: #00ccff;
  --green: #22dddd;
  --blue: #1300ff;
  --light-blue: #b8d8ff;
  --bright-blue: #00abe4;
  --dusk: #6600ff;
  --purple: #9900ff;
  --pink: #ff0066;
  --red: #fe0222;
  --soft-red: #ffa895;
  --extra-soft-red: #ffc9ba;
  --orange: #fd7702;
  --soft-orange: #ffbb48;
  --extra-soft-orange: #ffd187;
  /*--soft-orange: #f3b248;*/
  --yellow: #ffbb00;

  --background: var(--light-blue);
  /*--background: #5ADFDF;*/
  --accent: var(--black);

  background-color: var(--background);
  color: var(--accent);

  --footer-height: 60px;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
}

#root {
  /*height: 3000vh;*/
  /*overflow-y: visible;*/
  /*overflow-x: hidden;*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
