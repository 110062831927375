h1 {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: -1px;
    line-height: 1.2;
    text-align: center;
    margin: 100px 0 40px;
}

h2 {
  font-size: 2.5rem;
  margin: 50px 50px;
}

h4 {
  font-size: 1.5rem;
}

h5 {
  font-size: 1.2rem;
}

p {
  margin: 0 0 30px 0;
  font-size: 18px;
}

footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  background-image: radial-gradient(
          rgba(0, 0, 0, 0) 1px,
          var(--background) 1px
  );
  background-size: 4px 4px;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  -moz-backdrop-filter: blur(3px);
  font-size: 14px;
  line-height: 14px;
}

footer::before {
  display: block;
  content: "";
  position: absolute;
  top: -1px;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--accent);
  opacity: 0.2;
}

footer svg {
  margin-right: 20px;
}

footer a {
  text-decoration: none;
  color: var(--accent);
}

code {
  font-family: input-mono, monospace;
  font-weight: 400;
  font-style: normal;
}

::-webkit-scrollbar {
  height: 12px;
  width: 5px;
  background: var(--background);
}

::-webkit-scrollbar-thumb {
  background: var(--accent);
  -webkit-border-radius: 1ex;
}

::-webkit-scrollbar-corner {
  background: var(--background);
}

.side-projects-scroll-ref {
  position: absolute;
  top: 600px;
}

.side-projects-container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.side-project-caption-overlay {
    position: absolute;
    top: 700px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
}

.side-project-links {
    display: flex;
    justify-content: center;
}

a.white-link {
    color: #fff;
}

a:visited.white-link {
    color: #fff;
}

.container div canvas {
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: pixelated;
  image-rendering: optimize-contrast;
}
