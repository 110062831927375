.projects-container {
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--white);
    background-color: var(--bright-blue);
}
@media (max-width: 900px) {
    .projects-container {
        height: 50px;
    }
    .projects-container h2{
        font-size: 1.5rem;
    }
}