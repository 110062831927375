.header-container {
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--bright-blue);
}

.header-favicon {
  width: 36px;
  height: 36px;
  margin: 10px;
}

.header-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-link {
  color: var(--white);
  font-size: 1.5em;
  margin: 15px;
  cursor: pointer;
}

a.header-link {
  text-decoration: none;
}

.header-locale-icon-svg {
  width: 36px;
  height: 36px;
  margin: 10px;
  cursor: pointer;
}

@media (max-width: 900px) {
  .header-links {
    display: none;
  }
  /* .banner-skills-text-row {
    font-size: smaller;
  } */
}
